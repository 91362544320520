.NavBarItems { 
    background: #1d2226;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo { 
    color: #ffff;
    justify-self: start;
    margin-left: 20px;
    height: auto;
    width: auto;
    max-width: 90px;
    max-height: 90px;   
}
.pfp-img{
    border-radius: 100px;
}
i { 
    padding: 5px;
}

.nav-menu { 
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links { 
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}
.nav-links:hover { 
    background-color: #526e88;
    border-radius: 20px;
    transition: all 0.2s ease-out;
}

.fa-bars { 
    color: #ffff;
}

.nav-links-mobile { 
    display: none;
}

.menu-icon { 
    display: none;
}


@media screen and (max-width: 960px ) { 
    .NavBarItems { 
        position: relative;
    }
    .nav-menu { 
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active { 
        background: #32393f;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;

    } 

    .nav-links { 
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover { 
        background-color: #526e88;
        border-radius: 40px;
        transition: 400ms;
    }

    .navbar-logo {
        position: absolute;
        top: -35px;
        left: -2rem;
        transform: translate(25%, 50%);
    }

    .menu-icon { 
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times { 
        color: #ffff;
        font-size: 2rem;
    }


    .nav-links-mobile { 
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover { 
        background: white;
        color: #6568f4;
        transition: 250ms;
    }
}

